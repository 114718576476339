// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-aengusm-gatsby-theme-brain-src-templates-brain-js": () => import("./../node_modules/@aengusm/gatsby-theme-brain/src/templates/brain.js" /* webpackChunkName: "component---node-modules-aengusm-gatsby-theme-brain-src-templates-brain-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-guatemala-js": () => import("./../src/pages/guatemala.js" /* webpackChunkName: "component---src-pages-guatemala-js" */),
  "component---src-pages-medellin-js": () => import("./../src/pages/medellin.js" /* webpackChunkName: "component---src-pages-medellin-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

